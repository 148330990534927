import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import moment from "moment";
import DateTimePicker from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { Button, Input, Form, FormField } from "semantic-ui-react";

export default class NewMeasurement extends React.Component {
  static propTypes = {
    source: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
  };

  state = {
    value: 0,
    sourceCreated: moment(),
    saving: false,
  };

  saveMeasurement = () => {
    const { source, name, unit } = this.props;
    const { value, sourceCreated, saving } = this.state;
    if (value === null || saving) {
      return;
    }
    this.setState({ saving: true });
    axios
      .post("https://api.reefmonitors.com/v1/metric", [
        {
          source,
          name,
          value,
          unit,
          source_created: sourceCreated.valueOf() / 1000.0,
        },
      ])
      .then(() => {
        this.setState({ saving: false });
      });
  };

  onValueChanged = ({ target: { valueAsNumber } }) => {
    this.setState({ value: valueAsNumber });
  };

  render() {
    const { sourceCreated, value } = this.state;
    const { unit, name } = this.props;
    return (
      <div>
        <Form>
          New {name} Measurement
          <Form.Group widths="equal">
            <FormField>
              <label>Measurement Time</label>
              <DateTimePicker
                onChange={(sourceCreated) => this.setState({ sourceCreated })}
                value={sourceCreated}
              />
            </FormField>
            <FormField>
              <label>{unit}</label>
              <Input type="number" value={value} onInput={this.onValueChanged} />
            </FormField>
          </Form.Group>
          <Button onClick={this.saveMeasurement} color="green">
            Save
          </Button>
        </Form>
      </div>
    );
  }
}
