import React, { useEffect } from "react";
import axios from "axios";
import moment from "moment";
import map from "lodash/map";
import {
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  FlexibleWidthXYPlot,
  YAxis,
  LineSeries,
  Crosshair,
} from "react-vis";
import "react-vis/dist/style.css";
import NewMeasurement from "./NewMeasurement";
import { Header } from "semantic-ui-react";

export default class Graph extends React.Component {
  state = {
    metrics: [],
    crossHairValues: [],
    interval: null,
  };

  static defaultProps = {
    source: "ff81f55c-8c1a-4fc0-9e9d-c39ecb8c94af",
    name: "Temp",
    allowCustomMetrics: false,
  };

  componentDidMount() {
    this.loadMetrics();
    const interval = setInterval(() => this.loadMetrics(), 60 * 1000);
    this.setState({ interval });
  }

  componentDidUpdate({ start, stop }) {
    if (start !== this.props.start || stop !== this.props.stop) {
      this.loadMetrics();
    }
  }

  componentWillUnmount() {
    const { interval } = this.state;
    if (interval !== null) {
      clearInterval(interval);
    }
  }

  loadMetrics() {
    const { source, name, conversion, start, stop } = this.props;
    return axios
      .get("https://api.reefmonitors.com/v1/filtered-metric", {
        params: {
          source: source,
          name: name,
          start: start.unix(),
          stop: stop.unix(),
        },
      })
      .then(({ data: { metrics } }) => {
        const actualMetrics = map(metrics, ({ value, source_created }) => {
          value = conversion(value);
          return { y: value, x: moment.utc(source_created) };
        });
        this.setState({ metrics: actualMetrics });
      });
  }

  render() {
    const { metrics, crossHairValues } = this.state;
    const {
      min,
      max,
      source,
      name,
      unit,
      allowCustomMetrics,
      start,
      stop,
    } = this.props;
    return (
      <div>
        <Header>{name}</Header>
        <div>
          <FlexibleWidthXYPlot
            height={300}
            xType="time"
            yDomain={[min, max]}
            xDomain={[start, stop]}
            onMouseLeave={() => this.setState({ crossHairValues: [] })}
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis />
            <YAxis />
            <LineSeries
              data={metrics}
              onNearestX={(value, { index }) => {
                const { x, y } = metrics[index];
                this.setState({ crossHairValues: [{ x: x, y }, { x: x, y: 0 }] });
              }}
            />
            <Crosshair values={crossHairValues} xType={"time"}>
              <div className="rv-crosshair__inner__content">
                {crossHairValues.length > 0 && (
                  <div>
                    <div>
                      Time: {crossHairValues[0].x.local().format("YYYY-MM-DD LTS")}
                    </div>
                    <div>Value: {crossHairValues[0].y}</div>
                  </div>
                )}
              </div>
            </Crosshair>
          </FlexibleWidthXYPlot>
        </div>
        {allowCustomMetrics && (
          <NewMeasurement source={source} name={name} unit={unit} />
        )}
      </div>
    );
  }
}
