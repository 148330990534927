import React from "react";
import moment from "moment";
import DateTimePicker from "react-datetime";
import { Form } from "semantic-ui-react";

import "./App.css";
import Graph from "./components/Graph";
import GraphGroup from "./components/GraphGroup/index";
import LastValue from "./components/LastValue";

const SIDE_ROOM_SOURCE = "8448b192-4122-4e96-b2de-f43b57c300c2";

export default class App extends React.Component {
  state = {
    start: moment().subtract(2, "d"),
    stop: moment().add(1, "h"),
  };

  render() {
    const cToF = (val) => (val * 9) / 5 + 32;
    const noConversion = (val) => val;
    const boolConversion = (val) => (val === 1 ? "ON" : "OFF");
    const { start, stop } = this.state;
    return (
      <div className="App">
        <Form style={{ display: "flex", "justify-content": "center" }}>
          <Form.Group>
            <Form.Field>
              <label>Start</label>
              <DateTimePicker
                onChange={(start) => this.setState({ start })}
                value={start}
              />
            </Form.Field>
            <Form.Field>
              <label>Stop</label>
              <DateTimePicker
                onChange={(stop) => this.setState({ stop })}
                value={stop}
              />
            </Form.Field>
          </Form.Group>
        </Form>
        <GraphGroup>
          <Graph
            name="tank1-temperature"
            min={70}
            max={85}
            conversion={cToF}
            unit="C"
            start={start}
            stop={stop}
          />
          <Graph
            name="tank2-temperature"
            min={70}
            max={85}
            conversion={cToF}
            unit="C"
            start={start}
            stop={stop}
          />
          <Graph
            name="humidity"
            min={0}
            max={100}
            conversion={noConversion}
            unit="percentage"
            start={start}
            stop={stop}
          />
          <Graph
            name="ambient-temperature"
            min={50}
            max={90}
            conversion={cToF}
            unit="C"
            start={start}
            stop={stop}
          />
          <Graph
            name="tank-ph"
            min={7.0}
            max={9.0}
            conversion={noConversion}
            unit="pH"
            start={start}
            stop={stop}
          />
          <Graph
            name="alkalinity"
            min={7}
            max={12}
            conversion={noConversion}
            unit="dKH"
            allowCustomMetrics
            start={start}
            stop={stop}
          />
          <Graph
            name="heaters-on"
            min={0}
            max={2}
            conversion={noConversion}
            unit="bool"
            start={start}
            stop={stop}
          />
          <LastValue name="heaters-on" conversion={boolConversion} />
          <Graph
            source="7c887ed2-afd7-478e-9c41-63c62b37f680"
            name="display-tank-temp"
            min={75}
            max={83}
            conversion={cToF}
            unit="C"
            start={start}
            stop={stop}
          />
          <Graph
            source="7c887ed2-afd7-478e-9c41-63c62b37f680"
            name="display-water-level-high"
            min={0}
            max={2}
            conversion={noConversion}
            unit="bool"
            start={start}
            stop={stop}
          />
          <LastValue
            source="7c887ed2-afd7-478e-9c41-63c62b37f680"
            name="display-water-level-high"
            conversion={boolConversion}
          />
          <Graph
            name="refugium-light-on"
            min={0}
            max={2}
            conversion={noConversion}
            unit="bool"
            start={start}
            stop={stop}
          />
          <Graph
            name="sump-conductivity"
            min={40000}
            max={60000}
            conversion={noConversion}
            unit="uS"
            start={start}
            stop={stop}
          />
          <Graph
            name="co2-concentration"
            source={SIDE_ROOM_SOURCE}
            min={0}
            max={2000}
            conversion={noConversion}
            unit="ppm"
            start={start}
            stop={stop}
          />
        </GraphGroup>
      </div>
    );
  }
}
