import React from "react";
import axios from "axios";
import moment from "moment";
import "react-vis/dist/style.css";
import { Header } from "semantic-ui-react";

export default class LastValue extends React.Component {
  state = {
    metric: null,
    crossHairValues: [],
    interval: null,
  };

  static defaultProps = {
    source: "ff81f55c-8c1a-4fc0-9e9d-c39ecb8c94af",
    name: "Temp",
    allowCustomMetrics: false,
  };

  componentDidMount() {
    this.loadMetrics();
    const interval = setInterval(() => this.loadMetrics(), 60 * 1000);
    this.setState({ interval });
  }

  componentWillUnmount() {
    const { interval } = this.state;
    if (interval !== null) {
      clearInterval(interval);
    }
  }

  loadMetrics() {
    const { source, name } = this.props;
    return axios
      .get("https://api.reefmonitors.com/v1/last-metric", {
        params: {
          source,
          name,
        },
      })
      .then(({ data: { value, source_created } }) => {
        const metric = { value, source_created: moment.utc(source_created) };
        this.setState({ metric });
      });
  }

  render() {
    const { metric } = this.state;
    const { name, conversion } = this.props;
    return (
      <div>
        <Header>{name}</Header>
        {metric ? (
          <Header>
            {conversion(metric.value)}
            <br />
            {metric.source_created.local().format("YYYY-MM-DD LTS")}
          </Header>
        ) : (
          <Header>Loading</Header>
        )}
      </div>
    );
  }
}
